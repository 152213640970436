import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './TelanganaNijam-logo.png';

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className='position-fixed w-100 top-0 z-index-100'>
            <div className='text-center bg_header'>
                <img src={Logo} className='cursor_pointer' onClick={() => navigate('/home')} style={{ height: '65px' }} />
            </div>
            <div className='nav-bar-css'>
                <div onClick={() => navigate('/home')} className='a-title'>Home</div>
                <div onClick={() => navigate('/local-news')} className='a-title'>Local News</div>
                <div onClick={() => navigate('/international')} className='a-title'>International</div>
                <div onClick={() => navigate('/politics')} className='a-title'>Politics</div>
                <div onClick={() => navigate('/sports')} className='a-title'>Sports</div>
                <div onClick={() => navigate('/entertainment')} className='a-title'>Entertainment</div>
                <div onClick={() => navigate('/contact')} className='a-title'>Contact Us</div>
            </div>
        </div>
    );
};

export default Header;