import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GLOBAL_DOMAIN, GLOBAL_VERSION, GLOBAL_IMAGES, GLOBAL_CONTAINER } from '../Config/Config';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import share_icon from '../assets/Share_icon_bule.svg';
import whatsapp_icon from '../assets/whatsapp.png';
import copy_icon from '../assets/copy.png';
import facebook_icon from '../assets/facebook.svg';

const Info = () => {
    const [selectedarticals, setselectedarticals] = useState();
    const params = useParams();
    const [show, setShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'newsarticles/' + params.id,
            // params: {
            //     type: "list",
            //     limit: 100,
            //     // date: date,
            //     // stateId: selectedstateId,
            //     // districtId: selecteddistrictsId,
            //     // timezone: timezone
            // }
        }).then((res) => {
            console.log(res);
            // let selectedarticels = res.data?.rows.find((it) => it.id == params.id);
            setselectedarticals(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        // // textAreaRef.current.select();
        // document.execCommand('copy');
        // // This is just personal preference.
        // // I prefer to not show the whole text area selected.
        // // e.target.focus();
        setCopySuccess(true);
        setShow(false);
        setTimeout(() => {
            setCopySuccess(false);
        }, 5000);
    };

    const sendtowhatsapp = (event) => {
        setShow(false);
        const whatsapp_url = 'https://api.whatsapp.com/send?text=' + event;
        window.open(whatsapp_url);
    }

    const sendtofacebook = (event) => {
        setShow(false);
        const facebook_url = 'https://www.facebook.com/sharer/sharer.php?u=' + event;
        window.open(facebook_url);
    }

    return (
        <Container className='mt-20vh'>
            {selectedarticals ?
                <div className='my-3'>
                    {/* <div className='card'> */}
                    <div className='d-flex justify-content-between justify-items-center'>
                        <div>
                            <p>{selectedarticals.category_name} / {selectedarticals.title}</p>
                        </div>
                        <div className='position-relative width_10vw text-end'>
                            <div>
                                <img className='cursor_pointer' src={share_icon} alt='share icon' onClick={() => setShow(true)} />
                            </div>
                            {copySuccess &&
                                            <div className='position-absolute end-0'>
                                                <small className='m-0 w-100 color_blue'>Link Copied</small>
                                            </div>
                            }
                            {show &&
                                <div className='share_card'>
                                    <div className='m-2 d-flex align-items-center border p-2 cursor_pointer' onClick={() => sendtowhatsapp(window.location.href)}>
                                        <div
                                            className="share-icon"><img src={whatsapp_icon} width={30} /></div>
                                        {/* <p className='m-0 px-2'>whatsapp</p> */}
                                    </div>
                                    <div onClick={() => copyToClipboard(window.location.href)} >
                                        <div className='m-2 d-flex align-items-center border p-2 cursor_pointer'>
                                            <img src={copy_icon} width={30} />
                                            {/* <p className='m-0 px-2'>Copy</p> */}
                                        </div>
                                    </div>
                                    <div>
                                         <div className='m-2 d-flex align-items-center border p-2 cursor_pointer' onClick={() => sendtofacebook(window.location.href)}>
                                        <div
                                            className="share-icon"><img src={facebook_icon} width={30} /></div>
                                        {/* <p className='m-0 px-2'>Facebook</p> */}
                                    </div>
                                        </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/* </div> */}
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={GLOBAL_IMAGES + GLOBAL_CONTAINER + selectedarticals.image_path}
                                onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                alt='news paper'
                                className='img-fluid'
                            />
                        </div>
                        {/* <div className='col-12 col-sm-6 col-md-6 col-lg-6 px-3'>
                            <div className='text-center'>
                                <h3>
                                    {selectedarticals.title}
                                    <sup className='fs-12 mx-2'>
                                        ({selectedarticals.category_name})
                                    </sup>
                                </h3>
                            </div>
                            <div>
                                <p>
                                    {selectedarticals.description}
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
                :
                <div className='m-3'>
                    <h3> Fetching Data...</h3>
                </div>
            }
        </Container>
    );
};

export default Info;