import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { GLOBAL_DOMAIN, GLOBAL_VERSION, GLOBAL_IMAGES, GLOBAL_CONTAINER } from '../Config/Config';
import axios from 'axios';

const Home = () => {
    const navigate = useNavigate();
    const [newsarticles, setarticles] = useState([]);
    const [date, setdate] = useState();
    const handleShow = (event) => {
        // console.log(title); 
        navigate(`/info/${event.id}`);
    };
    useEffect(() => {
        let today = new Date();
        setdate(formatDate(today));
        GETNews();
    }, []);

    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    const GETNews = () => {
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'newsarticles?',
            params: {
                type: "list",
                limit: 7,
                date: date,
                // stateId: selectedstateId,
                // districtId: selecteddistrictsId,
                // timezone: timezone
            }
        }).then((res) => {
            console.log(res);
            setarticles(res?.data?.rows);
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <Container className='mt-20vh'>
            <Row>
                {newsarticles.length > 0 ? newsarticles.map((it, idx) => <Col xs={12} sm={4} key={idx}>
                    <div className='artical_image mt-3 rounded cursor_pointer' style={{ backgroundImage: `url( "${(GLOBAL_IMAGES + GLOBAL_CONTAINER + it.image_path) || 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}")`, }} onClick={() => handleShow(it)}>
                        <div className='artical_title px-3 rounded'>
                            {it.title}
                        </div>
                    </div>
                </Col>)
                    :
                    <div className='mt-3'>
                        <h2>Fetching Data...</h2>
                    </div>
                }
            </Row>
        </Container>
    );
};

export default Home;