import React from "react";

import { Routes, Route } from 'react-router-dom';

import Contact from './component/contact';
import Home from "./component/home";
import Header from "./component/header";
import Info from "./component/info";
import Errorpage from "./component/Common/404";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/local-news" element={<Home />} />
          <Route path="/international" element={<Home />} />
          <Route path="/politics" element={<Home />} />
          <Route path="/sports" element={<Home />} />
          <Route path="/entertainment" element={<Home />} />
          <Route path="/info/:id" element={<Info />} />
          <Route path="*" element={<Errorpage />} />
        </Routes>
        {/* <Contact /> */}
      </div>
    </div>
  );
};

export default App;