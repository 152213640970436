import React from 'react'
import { Container } from 'react-bootstrap';

const Errorpage = () => {
    return (
        <Container>
        <div>
            <div className='text-center my-3' >
                <h1 className='color_blue' style={{fontSize:'15vw'}}>404</h1>
                <h1>Sorry, Page Not Found</h1>
                <h3>The page you requested does not exit</h3>
            </div>
        </div>
        </Container>
    );
};

export default Errorpage;